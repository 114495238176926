var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "mdl-loading",
          { ref: "loadingBox", on: { load: _vm.getDatas } },
          [
            _c(
              "mdl-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "80",
                  "label-align": "justify",
                  form: _vm.form,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "mdl-form-item",
                  { attrs: { label: "用户名", field: "username" } },
                  [
                    _c("mdl-input", {
                      attrs: { placeholder: "请输入用户名" },
                      model: {
                        value: _vm.form.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "username", $$v)
                        },
                        expression: "form.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "mdl-form-item",
                  { attrs: { label: "密码", field: "password" } },
                  [
                    _c("mdl-input-password", {
                      attrs: { placeholder: "请输入密码" },
                      model: {
                        value: _vm.form.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "mdl-form-item",
                  { attrs: { label: "重复密码", field: "pwdAgain" } },
                  [
                    _c("mdl-input-password", {
                      attrs: { placeholder: "请输入重复密码" },
                      model: {
                        value: _vm.form.pwdAgain,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pwdAgain", $$v)
                        },
                        expression: "form.pwdAgain",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "mdl-form-item",
                  { attrs: { label: "邮箱", field: "email" } },
                  [
                    _c("mdl-input", {
                      attrs: { placeholder: "请输入邮箱" },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "sign-box" },
              [
                _c(
                  "mdl-button",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { type: "button", round: "" },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("注册 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }