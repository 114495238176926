<template>
	<div>
		<div>	
			<mdl-loading ref="loadingBox" @load="getDatas">
				<mdl-form label-width="80" label-align="justify" ref="form" :form="form" :rules="rules">
					<mdl-form-item label="用户名" field="username">
						<mdl-input placeholder="请输入用户名" v-model="form.username" />
					</mdl-form-item>
					<mdl-form-item label="密码" field="password">
						<mdl-input-password v-model="form.password" placeholder="请输入密码" />
					</mdl-form-item>
					<mdl-form-item label="验证码" field="captcha">
						<div class="cpatcha-box">
							<mdl-input placeholder="请输入验证码" v-model="form.captcha" style="width:calc(100% - 170px);" />
							<div class="captcha-img-box" v-loading="inLoadImage">
								<img v-if="captchaDefault" :src="captchaImage" @click="getCaptchaImage" />
								<div v-else v-html="captchaImage" @click="getCaptchaImage"></div>
							</div>
						</div>
					</mdl-form-item>
				</mdl-form>
				<div class="sign-box">
					<mdl-button type="button" style="width:100%;" @click="onSubmit" round>登录</mdl-button>
					<div style="margin-top:10px;">
						<mdl-button type="text" @click="onShowDialog('signup')">免费注册</mdl-button>
						<mdl-button type="text" @click="onShowDialog('activate')">激活用户</mdl-button>
						<mdl-button type="text" @click="onShowDialog('forget')">忘记密码</mdl-button>
					</div>
				</div>
			</mdl-loading>
		</div>
		<mdl-dialog title="免费注册" :visible="dialogVisible.signup" append-to-body width="500px" @close-dialog="onCloseDialog('signup')">
			<signup ref="signup" v-if="dialogVisible.signup" @close="onCloseDialog('signup')" />
		</mdl-dialog>
	</div>
</template>
<script>
import userApi from "@/apis/user";
import toolApi from "@/apis/tool";

import signup from "./signup";

export default {
	components: {
		signup
	},
	data () {
		return {
			captchaDefault: true,
			captchaImage: "/res/images/default-captcha.svg", // 默认图片
			callback: null,
			dialogVisible: {
				signup: false
			},
			form: {
				username: "",
				password: "",
				captcha: ""
			},
			rules: {
				username: {
					required: true,
					trigger: "blur"
				},
				password: {
					required: true,
					trigger: "blur"
				},
				captcha: {
					required: true,
					trigger: "blur",
					validate: (value, callback) => {
						console.log(value);
						callback();
					}
				}
			}
		}
	},
	methods: {
		async onClosed () {
			return await $utils.closeDialogConfirm(this);
		},
		async getDatas (callback) {
			try {
				callback = callback || this.loading;
				await this.getCaptchaImage();
				this.loading = callback;
			} catch (err) {
				console.log(err);
				callback.err();
			} finally {
				callback.end();
			}
		},
		async getCaptchaImage () {
			let res = await toolApi.getCaptcha();
			let value = res.value;
			value = value.replace('width="200" height="50"', 'width="160" height="40"'); // 重整大小
			this.captchaImage = value;
			this.captchaDefault = false;
		},
		async onSubmit () {
			try {
				this.loading.start();

				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.$message({
						message: "请先完成所有必填项!",
						type: "warning"
					});
					return;
				}

				// 后续提交
				const submitData = Object.assign({}, this.form);
				submitData.username = encodeURIComponent(submitData.username);
				const res = await userApi.signin(submitData);

				this.$message({
					message: res.message,
					closeable: true,
					type: res.success ? "success" : "warning"
				});

				if (res.success) {
					let token = res.value.token;
					delete res.value.token;
					Ls.set("USER_INFO", res.value.user);
					Ls.set("ACCESS_TOKEN", token);

					this.$emit("close");
				}

			} catch (err) {
				console.log(err);
				this.loading.err();
			} finally {
				this.loading.end();
			}
		},
		onShowDialog (refName) {
			this.dialogVisible[refName] = true;
		},
		async onCloseDialog (refName, ignore) {
			if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
				this.dialogVisible[refName] = false;
				delete this.$refs[refName];
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.captcha-img-box {
	margin-left:10px;
	width: 160px;
	height: 40px;
	cursor: pointer;
}

.cpatcha-box >div {
	display: inline-block;
}


.sign-box {
	width: 100%;
	margin-top: 5px;
	text-align: right;
}
</style>