<template>
	<div :style="`border-color: var(--c_${type}); background: var(--c_${type});`"
		:class="`mdl-tag-box`"><slot></slot></div>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: "info" // info success danger warning
		}
	}
}
</script>
<style lang="scss" scoped>
.mdl-tag-box {
    position: relative;
	padding: 4px 10px 4px 15px;
	margin-right: 10px;
	margin-bottom: 5px;
	font-size: 12.1px;
	border-width: 1px;
	border-style: solid;
	color: var(--c_white);
	clip-path: polygon(0% 50%,
                  12% 0,
                  100% 0%,
                  100% 100%,
                  12% 100%,
                   0% 50%);
}
</style>