var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" }, on: { click: _vm.cancelPop } },
    [
      _c("div", { staticClass: "menu-box" }, [
        _c(
          "div",
          {
            staticClass: "setting-btn-box",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showMenu.apply(null, arguments)
              },
            },
          },
          [_c("div")]
        ),
        _vm.isShowMenu
          ? _c("div", { staticClass: "menu" }, [
              _c(
                "ul",
                _vm._l(_vm.modules, function (mod) {
                  return _vm.canShow(mod)
                    ? _c(
                        "li",
                        {
                          key: mod.path,
                          on: {
                            click: function ($event) {
                              return _vm.onMod(mod.path)
                            },
                          },
                        },
                        [_vm._v(_vm._s(mod.name))]
                      )
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "user-box" }, [
          _c("div", { staticClass: "user" }, [
            _c("img", {
              staticClass: "user-img",
              attrs: { src: _vm.userInfo.avatar },
              on: { click: _vm.onClickUser },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "main-box" }, [
        _c("div", { staticClass: "page-name" }, [
          _vm._v(_vm._s(_vm.getPageName())),
        ]),
        _c(
          "div",
          { staticStyle: { height: "calc(100% - 60px)" } },
          [
            _c("router-view", {
              staticClass: "router-view-box",
              on: { page: _vm.setPageName },
            }),
          ],
          1
        ),
      ]),
      _c(
        "mdl-dialog",
        {
          attrs: {
            title: "用户登录",
            visible: _vm.dialogVisible.signin,
            width: "500px",
          },
          on: {
            "close-dialog": function ($event) {
              return _vm.onCloseDialog("signin")
            },
          },
        },
        [
          _vm.dialogVisible.signin
            ? _c("sign", {
                ref: "signin",
                on: {
                  close: function ($event) {
                    return _vm.onCloseDialog("signin")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }