var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mdl-table" }, [
    _c("table", [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm._columns, function (column, index) {
            return _c(
              "td",
              { key: index, style: column.style + _vm.headStyleStr },
              [_vm._v(" " + _vm._s(column.text) + " ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "tbody",
        {
          staticClass: "mdl-table-body",
          style:
            "overflow:auto;" + (_vm.height ? `height: ${_vm.height}px;` : ""),
        },
        _vm._l(_vm._data, function (row, index) {
          return _c(
            "tr",
            { key: index },
            _vm._l(_vm._columns, function (column, ji) {
              return _c("td", { key: ji, style: column.style + _vm.styleStr }, [
                _c("span", {
                  attrs: { id: `${column.name}_${index}` },
                  domProps: { innerHTML: _vm._s(_vm.getVal(row, column)) },
                }),
              ])
            }),
            0
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }