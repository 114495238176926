
import Layout from "@/components/layout";

export default [
  {
    path: '/',
    name: 'home',
    component: Layout,
    meta: { title: '首页', icon: 'home', noCache: true },
    children: [
      {
        path: '',
        component: resolve => require(['@/views/homepage'], resolve),
        name: 'home',
        meta: { title: '首页', noCache: true }
      },
    ]
  },
  {
    path: '/blog',
    name: 'blog',
    component: Layout,
    meta: { title: "我的博客", noCache: true },
    children: [
      {
        path: '',
        component: resolve => require(['@/views/blog'], resolve),
        name: 'blog',
        meta: { title: '我的博客', noCache: true }
      },
      {
        path: 'detail/:code',
        component: resolve => require(['@/views/blog'], resolve),
        name: 'blog-detail',
        meta: { title: '我的博客', noCache: true }
      }
    ]
  },
  {
    path: "/trails",
    name: "trails",
    component: Layout,
    meta: { title: "我的足迹", noCache: true },
    children: [
      {
        path: '',
        component: resolve => require(['@/views/trails'], resolve),
        name: 'trails',
        meta: { title: '我的足迹', noCache: true }
      },
    ]
  },
  {
    path: "/user",
    name: "user",
    component: Layout,
    meta: { title: "用户面板", noCache: true },
    children: [
      {
        path: '',
        component: resolve => require(['@/views/user'], resolve),
        name: 'user',
        meta: { title: '用户面板', noCache: true }
      },
    ]
  },
  {
    path: '/404',
    name: "404",
    component: resolve => require(['@/views/features/404'], resolve)
  },
  {
    path: '/redirect',
    component: Layout,
    name: "redirect",
    children: [
      {
        path: '/redirect/:path*',
        component: resolve => require(['@/views/features/redirect'], resolve)
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
];
