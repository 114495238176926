/**
 * 载入所有和用用户相关的处理
 */

// 用户详细信息

// 用户登出
export const signout = () => {
	// 因为服务端现在不做登出处理，所以这里野暂时没有任何动作
};

// 用户注册

// 用户总数
export const getUserCount = () => {
	return $http.get("/user/count");
};

// 用户登录
export const signin = (data) => {
	return $http.post("/user/signin", data);
};

export const signup = (data) => {
	return $http.post("/user/signup/create", data);
};
export const activate = (data) => {
	return $http.post("/user/signup/activate", data);
};

export const confirm = (data) => {
	return $http.post("/user/forget/confirm", data);
};
export const updPwd = (data) => {
	return $http.post("/user/forget/reset", data);
};


export default {
	confirm,
	updPwd,
	getUserCount,
	signin,
	signout,
	updPwd,
	signup,
	activate
};