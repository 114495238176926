export const getCaptcha = (data) => {
	return $http.get("/tool/captcha", data);
};

export const token = () => {
	return $http.get("/tool/token", data);
};

export default {
	getCaptcha,
	token
};