var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "mdl-loading",
            { ref: "loadingBox", on: { load: _vm.getDatas } },
            [
              _c(
                "mdl-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "80",
                    "label-align": "justify",
                    form: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "mdl-form-item",
                    { attrs: { label: "用户名", field: "username" } },
                    [
                      _c("mdl-input", {
                        attrs: { placeholder: "请输入用户名" },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "mdl-form-item",
                    { attrs: { label: "密码", field: "password" } },
                    [
                      _c("mdl-input-password", {
                        attrs: { placeholder: "请输入密码" },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "mdl-form-item",
                    { attrs: { label: "验证码", field: "captcha" } },
                    [
                      _c(
                        "div",
                        { staticClass: "cpatcha-box" },
                        [
                          _c("mdl-input", {
                            staticStyle: { width: "calc(100% - 170px)" },
                            attrs: { placeholder: "请输入验证码" },
                            model: {
                              value: _vm.form.captcha,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "captcha", $$v)
                              },
                              expression: "form.captcha",
                            },
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.inLoadImage,
                                  expression: "inLoadImage",
                                },
                              ],
                              staticClass: "captcha-img-box",
                            },
                            [
                              _vm.captchaDefault
                                ? _c("img", {
                                    attrs: { src: _vm.captchaImage },
                                    on: { click: _vm.getCaptchaImage },
                                  })
                                : _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.captchaImage),
                                    },
                                    on: { click: _vm.getCaptchaImage },
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "sign-box" },
                [
                  _c(
                    "mdl-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "button", round: "" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("登录")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "mdl-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.onShowDialog("signup")
                            },
                          },
                        },
                        [_vm._v("免费注册")]
                      ),
                      _c(
                        "mdl-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.onShowDialog("activate")
                            },
                          },
                        },
                        [_vm._v("激活用户")]
                      ),
                      _c(
                        "mdl-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.onShowDialog("forget")
                            },
                          },
                        },
                        [_vm._v("忘记密码")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "mdl-dialog",
        {
          attrs: {
            title: "免费注册",
            visible: _vm.dialogVisible.signup,
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "close-dialog": function ($event) {
              return _vm.onCloseDialog("signup")
            },
          },
        },
        [
          _vm.dialogVisible.signup
            ? _c("signup", {
                ref: "signup",
                on: {
                  close: function ($event) {
                    return _vm.onCloseDialog("signup")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }