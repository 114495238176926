import SecureLS from "secure-ls";

const ls = new SecureLS({
	encodingType: "base64",
	isCompression: false,
	encryptionSecret: process.env.VUE_APP_ENCRYPT_SM4_KEY
});

function get (key) {
	if (key === null || key === undefined) {
		return null;
	}
	let str = ls.get(key);
	try {
		if (!str) {
			return null;
		}
		let obj = JSON.parse(str);
		return obj;
	} catch {
		return null;
	}
}

export default {
	set: (key, value) => {
		if (value === null || value === undefined) {
			return;
		}
		if (typeof (value) === "object") {
			value = JSON.stringify(value);
		}
		ls.set(key, value);
	},
	get,
	has: (key) => {
		let ret = get(key);
		return !!ret;
	},
	remove: (key) => {
		ls.remove(key);
	}
};
