import dayjs from "dayjs";
import axios from "axios";

String.reverse = (input) => {
	input = String(input);
	return input.split("").reverse().join("");
}


export function getMapDataList () {
	return [
		{name: '西藏', value: 0},
		{name: '上海', value: 0},
		{name: '广西', value: 0},
		{name: '广东', value: 0},
		{name: '云南', value: 0},
		{name: '香港', value: 0},
		{name: '澳门', value: 0},
		{name: '海南', value: 0},
		{name: '辽宁', value: 0},
		{name: '吉林', value: 0},
		{name: '宁夏', value: 0},
		{name: '青海', value: 0},
		{name: '内蒙古', value: 0},
		{name: '四川', value: 0},
		{name: '陕西', value: 0},
		{name: '重庆', value: 0},
		{name: '江苏', value: 0},
		{name: '贵州', value: 0},
		{name: '北京', value: 0},
		{name: '新疆', value: 0},
		{name: '浙江', value: 0},
		{name: '山东', value: 0},
		{name: '甘肃', value: 0},
		{name: '天津', value: 0},
		{name: '河南', value: 0},
		{name: '黑龙江', value: 0},
		{name: '河北', value: 0},
		{name: '湖南', value: 0},
		{name: '安徽', value: 0},
		{name: '湖北', value: 0},
		{name: '台湾', value: 0},
		{name: '山西', value: 0},
		{name: '福建', value: 0},
		{name: '江西', value: 0}
	];
}

export function isProductionMode () {
	return process.env.NODE_ENV === 'production'
}

export const isDevelopMode = () => {
	return process.env.NODE_ENV === 'development'
}

export const isTestMode = () => {
	return process.env.NODE_ENV === 'test'
}

export const formatTime = (date, formatter = "YYYY-MM-DD HH:mm:ss")=> {
	return dayjs(date).format(formatter);
}

export const readFile = async (filePath) => {

	const res = await axios.get(filePath);
	if (res.status = 200) {
		return res.data;
	} else {
		return null;
	}
}

export const closeDialogConfirm = (that) => {
	return new Promise((resolve, reject) => {
		const res = window.confirm("是否要关闭窗口？");
		resolve(res);
	});
	
	// return new Promise((resolve, reject) => {
	// 	that.$confirm("是否要关闭窗口？", "提示", {
	// 		confirmButtonText: '是',
	// 		cancelButtonText: '否',
	// 		type: "warning"
	// 	})
	// 	.then(() => {
	// 		resolve(true);
	// 	})
	// 	.catch(() => {
	// 		resolve(false);
	// 	});
	// });
};

export const checkNumberIsNotEmpty = (input) => {
	if (checkObjectIsNotEmpty(input)) {
		return !isNaN(input);
	} else {
		return false;
	}
	}
	
	export const checkObjectIsNotEmpty = (input) => {
	// 先去除掉 null、undefined、[]、""
	if (input === null || input === undefined || input.length === 0) {
		return false;
	} else {
		if (Array.isArray(input)) {
		for (const item of input) {
			if (!checkObjectIsNotEmpty(item)) {
			return false;
			}
		}
		return true;
		} else {
		return true;
		}
	}
	}

export default {
	isDevelopMode,
	isProductionMode,
	isTestMode,
	formatTime,
	readFile,
	closeDialogConfirm,
	checkNumberIsNotEmpty,
	checkObjectIsNotEmpty
}