<template>
	<div>aaaaa</div>
</template>
<script>
export default {
	props: {
		data: {
			type: Array,
			default: []
		}
	}
}
</script>