var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: `mdl-button mdl-button__${_vm.type}`,
      style: _vm.style,
      attrs: { type: _vm.nativeType, disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }