var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "mdl-message-list-box",
    },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          class: `mdl-message-box mdl-message-box__${item.round} mdl-message-box__${item.type}`,
        },
        [
          _c("div", { staticClass: "mdl-message-value-box" }, [
            _vm._v(" " + _vm._s(item.message)),
          ]),
          item.closeable
            ? _c(
                "div",
                {
                  staticClass: "mdl-message-close-box",
                  on: {
                    click: function ($event) {
                      return _vm.onClose(index)
                    },
                  },
                },
                [
                  _c("close-box", {
                    on: {
                      close: function ($event) {
                        return _vm.onClose(index)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }