var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `mdl-alert-box mdl-text-align__${_vm.align} mdl-alert-effect__${_vm.effect} mdl-alert-effect__${_vm.type}-${_vm.effect}`,
      style: `border-color: var(--c_${_vm.type});`,
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }