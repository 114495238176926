import Vue from 'vue';
import Router from 'vue-router';
import constantRouterMap from "./data";

Vue.use(Router);


const router = new Router({
  mode: 'hash',
  base: process.env.VUE_APP_BASE_PATH,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
});

router.beforeEach((route, from, next) => {
	let nextRoute = null;

	document.title = `${route.meta.title} - ${process.env.VUE_APP_TITLE}`;
	nextRoute ? next(nextRoute) : next();
});

export default router