import { sm2 } from "sm-crypto";

const cipherMode = 0; // 选择加密策略，1 - C1C3C2，0 - C1C2C3，默认为1
const sysPublicKey = process.env.VUE_APP_ENCRYPT_SM2_KEY; // 系统后台公钥
const uiPrivateKey = process.env.VUE_APP_DECRYPT_SM2_KEY; // 前端UI私钥
const useSecrecy = process.env.VUE_APP_USE_SECRECY === "1";

function encrypt (data) {
	if (!useSecrecy) {
		return data;
	}
	if (!sysPublicKey) {
		throw new Error("没有配置用于加密的 SM2Key");
	}
	if (data) {
		return "0x" + sm2.doEncrypt(data, window.atob(sysPublicKey), cipherMode);
	}
	return null;
}

function decrypt (dataHex) {
	if (!useSecrecy) {
		return dataHex;
	}
	if (!uiPrivateKey) {
		throw new Error("没有配置用于解密的 SM2Key");
	}
	if (dataHex) {
		if (typeof dataHex === "string" && dataHex.substring(0, 2) === "0x") {
			dataHex = dataHex.substring(2).toLowerCase();
			const res = sm2.doDecrypt(dataHex, window.atob(uiPrivateKey), cipherMode);
			return res;
		}
		return dataHex;
	}
}

export default {
	encrypt,
	decrypt
};
