import Vue from 'vue';
import App from './App.vue';

import './assets/style/index.scss';
import './assets/style/modell.scss';
import router from './routers';

import utils from "./utils/utils";
import http from "./utils/http";
import Ls from "./utils/ls";

import "coralian";
// import "coralian.client";

import frame from "./components/frame";

frame.mount(Vue);

Vue.$http = Vue.prototype.$http = http;
Vue.$utils = Vue.prototype.$utils = utils;
Vue.Ls = Ls;

window.$http = http;
window.$utils = utils;

window.Ls = Ls;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
