var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mdl-form-item-box" }, [
      _vm.required
        ? _c("div", { staticClass: "mdl-form-item-required-mark" }, [
            _vm._v("*"),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          class: `mdl-form-item-label mdl-text-align__${_vm.mdlForm.labelAlign}`,
          style: `width: ${_vm.mdlForm.labelWidth}px;`,
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c(
        "div",
        {
          style: `width: calc(100% - ${
            Number(_vm.mdlForm.labelWidth) + 30
          }px); ${_vm.style}`,
        },
        [_vm._t("default")],
        2
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "mdl-form-item-check-result-box",
        style: `margin-left:${Number(_vm.mdlForm.labelWidth) + 30}px;`,
      },
      [_vm._v(_vm._s(_vm.errorMessage))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }