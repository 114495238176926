<template>
	<div class="mdl-input-box" :style="style">
		<div v-if="append === 'before'" class="slot-box" :style="beforeStyles">
			<slot name="before"></slot>
		</div>
		<div style="width: 1px" />
		<div class="mdl-input-obj" :style="inputBoxStyles">
			<input class="mdl-input-obj-input" autocomplete="off" v-model="val"
				:disabled="disabled" :readonly="readonly" :style="inputStyles" :type="type" :placeholder="placeHolder" 
				@focus="onFocus" @blur="onBlur" />
		</div>
		<div style="width: 1px" />
		<div v-if="append === 'after'" class="mdl-slot-box" :style="afterStyles">
			<slot name="after"></slot>
		</div>
	</div>
</template>
<script>
export default {
	inject: [ "mdlForm", "mdlFormItem" ],
	props: {
		placeholder: {
			type: String,
			default: "请输入"
		},
		type: {
			type: String,
			default: "text"
		},
		append: {
			type: String,
			default: ""
		},
		style: {
			type: String,
			default: ""
		},
		readonly: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		value: {
			type: String,
			default: ""
		}
	},
	data () {
		return {
			placeHolder: "",
			val: "",
			beforeStyles: "",
			inputStyles : "",
			inputBoxStyles: "",
			afterStyles: "",
			rule: null
		}
	},
	watch: {
		"val": function(v1, v2) {
			this.mdlForm[this.mdlFormItem.field] = v1;
		},
		"value": function(v1) {
			this.mdlForm[this.mdlFormItem.field] = v1;
		}
	},
	created () {
		this.mdlFormItem.setField(this);
		this.placeHolder = this.placeholder;

		const append = this.append === "" ? [] : this.append.split(",");
		let inputStyles = "";
		if (append.indexOf("before") >= 0) {
			inputStyles += `border-top-left-radius: 0px; border-bottom-left-radius: 0px;`;
		}
		if (append.indexOf("after") >= 0) {
			inputStyles += `border-top-right-radius: 0px; border-bottom-right-radius: 0px;`;
		}

		let width = 23;
		let length = append.length;

		if (length > 0) {
			width = 20;
		}

		if (length === 0) {
			length = 1;
		}

		const widthStyle = `width: calc(100% - ${width * length}px);`;
		inputStyles += widthStyle;

		// this.inputBoxStyles = widthStyle;
		this.inputStyles = inputStyles;
	},
	methods: {
		onFocus () {
			this.placeHolder = "";
		},
		getRule () {
			const rule = this.rule || this.mdlForm.rules[this.mdlFormItem.field];
			if (!this.rule) {
				this.rule = rule;
			}
			return rule;
		},
		async onBlur () {
			this.placeHolder = this.placeholder;
			const rule = this.getRule();
			if (rule.trigger === "blur") {
				await this.validate();
			}
		},
		validate () {
			const rule = this.getRule();
			const that = this;
			return new Promise((resolve, reject) => {
				if (rule) {
					if (rule.required && !that.val) {
						const message = that.rule.message || `${that.mdlFormItem.label}是必填字段`;
						that.mdlFormItem.setMessage(message);
						resolve(false);
						return;
					}
					if (rule.validate) {
						that.rule.validate(that.val, (res) => {
							console.log(that.mdlFormItem.field, that.val, res);
							if (res) {
								that.mdlFormItem.errorMessage = res;
								that.mdlFormItem.setMessage(res);
								resolve(false);
								return;
							}
						});
					}
				}
				that.mdlFormItem.setValue(that.val);
				that.mdlFormItem.setMessage(null);
				resolve(true);
				return;
			});
		},
	}
}
</script>
<style lang="scss" scoped>
.mdl-input-box {
	width: 100%;
	>div {
		display: table-cell;
		vertical-align: middle;
	}
	.mdl-input-obj {
		width: 100%;
		.mdl-input-obj-input {
			height: 24px;
			padding:6px 10px;
			border: 1px solid var(--c_dark-gray);
			border-radius: 6px;
			outline: none;
		}
		.mdl-input-obj-input:focus {
			border: 1px solid var(--c_dark-blue);
		}
	}

	.mdl-slot-box {
		width: 1px;
		background: #EBEEF5;
		white-space: nowrap;
		height: 20px;
		line-height: 20px;
		text-align: center;
		padding: 8px 16px;
		border: 1px solid var(--c_dark-gray);
		border-left: 0px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		cursor: pointer;

		.mdl-input-append-btn {
			background-position-x: 0px;
			background-position-y: 0px;
			background-repeat: no-repeat;
			background-size: 20px;
			width: 18px;
			height: 20px;
		}
	}
}
</style>