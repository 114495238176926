<template>
	<div>
		<div>
			<mdl-loading ref="loadingBox" @load="getDatas">
				<mdl-form label-width="80" label-align="justify" ref="form" :form="form" :rules="rules">
					<mdl-form-item label="用户名" field="username">
						<mdl-input placeholder="请输入用户名" v-model="form.username" />
					</mdl-form-item>
					<mdl-form-item label="密码" field="password">
						<mdl-input-password v-model="form.password" placeholder="请输入密码" />
					</mdl-form-item>
					<mdl-form-item label="重复密码" field="pwdAgain">
						<mdl-input-password v-model="form.pwdAgain" placeholder="请输入重复密码" />
					</mdl-form-item>
					<mdl-form-item label="邮箱" field="email">
						<mdl-input v-model="form.email" placeholder="请输入邮箱" />
					</mdl-form-item>
				</mdl-form>
				<div class="sign-box">
					<mdl-button type="button" style="width:100%"
						@click="onSubmit" round>注册
					</mdl-button>
				</div>
			</mdl-loading>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			form: {
				username: "",
				password: "",
				pwdAgain: "",
				email: ""
			},
			rules: {
				username: {
					required: true,
					trigger: "blur"
				},
				password: {
					required: true,
					trigger: "blur"
				},
				pwdAgain: {
					required: true,
					trigger: "blur"
				},
				email: {
					required: true,
					trigger: "blur"
				},
			}
		}
	},
	methods: {
		async onClosed () {
			return await $utils.closeDialogConfirm(this);
		},
		getDatas (callback) {
			callback.end();
		}
	}
}
</script>