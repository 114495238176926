<template>
	<div @click="cancelPop" style="height: 100%;">
		<div class="menu-box">
			<div class="setting-btn-box" @click.stop="showMenu">
				<div></div><!-- 这是画左上角三横的中间那一条线的，所以不能删 -->
			</div>
			<div v-if="isShowMenu" class="menu">
				<ul>
					<li v-for="mod in modules" v-if="canShow(mod)" :key="mod.path" @click="onMod(mod.path)">{{ mod.name }}</li>
				</ul>
			</div>
			<div class="user-box">
				<div class="user">
					<img class="user-img" :src="userInfo.avatar" @click="onClickUser" />
				</div>
			</div>
		</div>
		<div class="main-box">
			<div class="page-name">{{ getPageName() }}</div>
			<div style="height: calc(100% - 60px);">
				<router-view class="router-view-box" @page="setPageName" />
			</div>
		</div>
		<mdl-dialog title="用户登录" :visible="dialogVisible.signin" width="500px" @close-dialog="onCloseDialog('signin')">
			<sign ref="signin" v-if="dialogVisible.signin" @close="onCloseDialog('signin')" />
		</mdl-dialog>
	</div>
</template>
<script>
import routes from "@/routers/data";

import sign from "./sign";


export default {
	components: {
		sign
	},
	data () {
		return {
			dialogVisible: {
				signin: false,
				user: false
			},
			routeMapping: null,
			isShowMenu: false,
			modules: [],
			user: null,
			pageName: "",
			nowPageName: null,
			userInfo: {
				uname: null,
				avatar: "/res/images/user.svg"
			},
		}
	},
	created () {
		this.setUserInfo();
		if (this.userInfo.uname == null && this.$route.name === "user") {
			this.$router.push({ name: "404" });
			return;
		}

		this.nowPageName = null;
		this.getDatas();
	},
	methods: {
		getDatas () {
			if (this.routeMapping === null) {
				this.loadRoutes();
			}
			
			this.pageName = this.$route.name;
		},
		showMenu () {
			this.isShowMenu = true;
		},
		onMod (path) {

			if (path === "user" && this.userInfo.uname === null) {
				this.$route.push({ name: 404 });
			}

			this.nowPageName = null;
			if (path === this.$route.name) return;
			this.pageName = path;

			if (path === 'home') {
				path = "";
			}

			this.$router.push({ path: `/${path}` });
			this.isShowMenu = false;

			this.$forceUpdate();
		},
		cancelPop () {
			this.isShowMenu = false;
		},
		setPageName (pageName) {
			this.nowPageName = pageName;
		},
		getPageName () {
			if (this.nowPageName) {
				return this.nowPageName;
			}
			if (this.routeMapping === null) {
				this.loadRoutes();
			}
			return this.routeMapping[this.$route.name];
		},
		loadRoutes () {
			this.loadModules();
			this.routeMapping = {};
			this.loadMapping(routes);
		},
		loadModules () {
			for (const route of routes) {
				if (route.meta) {
					this.modules.push({
						path: route.name,
						name: route.meta.title
					});
				}
			}
		},
		loadMapping (routes) {
			for (const route of routes) {
				if (route.meta) {
					this.routeMapping[route.name] = route.meta.title;
				}
				if (route.children) {
					this.loadMapping(route.children);
				}
			}
		},
		canShow (mod) {
			if (mod.path !== "user") {
				return true;
			}
			if (this.userInfo.uname === null) {
				return false;
			} else {
				return true;
			}
		},
		onClickUser () {
			if (this.userInfo.uname) {
				this.onMod("user");
			} else {
				this.onClickSign();
			}
		},
		onClickSign () {
			this.dialogVisible.signin = true;
		},
		setUserInfo () {
			const user = Ls.get("USER_INFO");
			if (user) {
				user.avatar = `${process.env.VUE_APP_BASE_URL}/statics/images/avatar/${user.avatar}.jpg`;
				this.userInfo = user;
			}
		},
		async onCloseDialog (refName, ignore) {
			if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
				this.dialogVisible[refName] = false;
				delete this.$refs[refName];

				if (this.userInfo.uname === null) {
					this.setUserInfo();
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>

body {
	height: 100%;
	background: #FFFF00;
}

.menu-box, .main-box {
	display: inline-block;
	vertical-align: top;
}

.menu-box {
	width: 80px;
	position: relative;

	.menu {
		position: absolute;
		left: 30px;
		top: 15px;
		width: 200px;
		background: #cbddf2;
		padding:10px;
		border-radius: 10px;
		z-index: 999999;

		ul, li {
			margin: 0px;
			padding: 0px;
			list-style: none;
		}

		li {
			padding:5px 10px;
			border-radius: 5px;
			color: #303133;
		}

		li:hover {
			color: #fae1b4;
			background: linear-gradient(90deg,#2342ff,#3fa6fd)!important;
			cursor: pointer;
		}
	}

	.setting-btn-box {
		width: 36px;
		height: 18px;
		border-top: 6px solid #450745;
		border-bottom: 6px solid #450745;
		cursor: pointer;
		>div {
			border:0px;
			height: 6px;
			margin-top: 6px;
			background: #450745;
		}
		.setting-btn-box:hover {
			background: #000000;
		}
	}
}

.main-box {
	width: calc(100% - 240px);
	height: 100%;
	.page-name {
		font-size: 24px;
		padding:5px 10px;
		font-weight: bold;
		color: #014387;
		background: #E0E4FE;
	}

	.router-view-box {
		height: 100%;
		// padding: 10px;
		// margin: 20px 0px;

		overflow: hidden;
		background: #F7F7F7;
	}
}

.user {
	margin-top: 10px;

	.user-img {
		border-radius: 50%;
		border:1px solid #CCCCCC;
		width: 60px;
		margin: 10px;
		margin-left: 5px;
		cursor: pointer;
		background: #FFFFFF;

	}
}

</style>