<template>
	<div :class="`mdl-alert-box mdl-text-align__${align} mdl-alert-effect__${effect} mdl-alert-effect__${type}-${effect}`"
		:style="`border-color: var(--c_${type});`"><slot></slot></div>
</template>
<script>
export default {
	props: {
		effect: {
			type: String,
			default: "light" // light、dark
		},
		type: {
			type: String,
			default: "info" // info、success、warning、danger
		},
		align: {
			type: String,
			default: "left" // left、center、right、justify
		}
	}
}
</script>
<style lang="scss" scoped>
.mdl-alert-box {
	padding:5px;
	margin: 5px 10px;
	font-size: 18px;
	border-radius: 10px;
	border-width: 1px;
	border-style: solid;
}
/////////////// ↓ 字体颜色 ↓ ///////////////
.mdl-alert-effect__dark {
	color: var(--c_white);
}
.mdl-alert-effect__info-light {
	color: var(--c_blue);
}
.mdl-alert-effect__text-light {
	color: var(--c_dark-gray);
}
.mdl-alert-effect__success-light {
	color: var(--c_success);
}
.mdl-alert-effect__warning-light {
	color: var(--c_earth_yellow);
}
.mdl-alert-effect__danger-light {
	color: var(--c_danger);
}

/////////////// ↓ 背景颜色 ↓ ///////////////
.mdl-alert-effect__light {
	background: var(--c_light-blue);
}

.mdl-alert-effect__info-dark {
	background: var(--c_blue);
}
.mdl-alert-effect__text-dark {
	background: var(--c_dark-gray);
}
.mdl-alert-effect__success-dark {
	background: var(--c_success);
}
.mdl-alert-effect__warning-dark {
	background: var(--c_earth_yellow);
}
.mdl-alert-effect__danger-dark {
	background: var(--c_danger);
}
</style>