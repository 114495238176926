var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c("div", [
        _c("div", {
          staticClass: "mdl-mask-layer-box",
          style: `z-index: ${_vm.zIndex};`,
          on: { click: _vm.onClose },
        }),
        _c(
          "div",
          {
            staticClass: "mdl-dialog-box",
            style: `z-index: ${_vm.zIndex + 1}; left: ${_vm.dialogLeft}; top: ${
              _vm.dialogTop
            }; width: ${_vm.dialogWidth}; height:${_vm.dialogHeight};`,
          },
          [
            _c("div", { staticClass: "mdl-dialog-title-box" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c(
              "div",
              {
                staticClass: "mdl-close-box mdl-dialog-close-box",
                on: { click: _vm.onClose },
              },
              [_c("close-box")],
              1
            ),
            _c(
              "div",
              { staticClass: "mdl-dialog-content-box" },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }